import { getExampleNumber } from "libphonenumber-js";
import countryCode from "./countryCode.json";
import examples from "libphonenumber-js/examples.mobile.json";

export const themes = {
  default: "default",
  dark: "dark",
};

/** Form Accent Color */
export const color = "#FFA811";

/** Form Error Color */
export const error = "#ff6b81";

export const checkLimit = (limit, number) => {
  if (Array.isArray(limit)) {
    // console.log("INSIDE CHECK LIMIT ARRAY")
    // console.log(number, typeof number)
    // console.log(checkFirstZeroAndAllZero(number))
    if (
      number?.toString()?.length === limit[0] ||
      number?.toString()?.length === limit[1]
    ) {
      if (!checkFirstZeroAndAllZero(number)) return true;
      else return false;
    }
    // else if(number.length === limit[0] || number.length === limit[1])
    else return false;
  }
  if (typeof limit === "object" && limit !== null) {
    console.log("INSIDE OBJECT");
    console.log(
      number.length === limit.lower_limit || number.length === limit.upper_limit
    );
  } else {
    if (number.length === limit) {
      if (!checkFirstZeroAndAllZero(number)) return true;
      else return false;
    } else return false;
  }
};

const custom_limit = (code) => {
  let phoneNumber = {};
  let limit;
  switch (code) {
    case "MY":
      limit = [9, 11];
      break;
    default:
      phoneNumber = getExampleNumber(code, examples);
      limit = phoneNumber?.nationalNumber?.length ?? 10;
      break;
  }
  return limit;
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return Boolean(re.test(email?.trim()));
};

export const validateDigits = (value) => {
  const re = /^[0-9]*$/;
  return Boolean(re.test(value?.trim()));
};

export const getMobileNumberLength = (countryISOCode, mobileno) => {
  const selectedCountry = countryCode?.filter(
    (cc) => cc.dial_code === countryISOCode.dial_code
  );
  console.log(selectedCountry);
  const ISO2Code = selectedCountry?.[0]?.code;
  // const phoneNumber = getExampleNumber(ISO2Code, examples);
  // const limit = phoneNumber?.nationalNumber?.length ?? 10;
  let limit = custom_limit(ISO2Code);
  return limit;
};

export const checkFirstZeroAndAllZero = (value) => {
  if (typeof value !== typeof "") value = value.toString();
  if (parseInt(value?.split("")[0]) === 0) {
    return true;
  } else {
    // console.log("INSIDE ELSE")
    let valueArr = value?.toString()?.split("") ?? [];
    valueArr.splice(0, 1);
    const digitArr = valueArr?.map((digit) => parseInt(digit));

    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    if (digitArr.length > 0) {
      if (digitArr?.reduce(reducer) === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
};

export const drawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

export const resourceType = {
  Service: "Service",
  Package: "Package",
  Product: "Product",
};

const colorJson = {
  0: "#F3A683",
  1: "#778BEB",
  2: "#E77F67",
  3: "#786FA6",
  4: "#F8A5C2",
  5: "#EA8685",
  7: "#D1CCC0",
  8: "#F7D794",
  9: "#596275",
};

export const avatarColorBgById = (id) => {
  let trim1 = id?.toString()?.replace(/^\D+/g, "");
  let trim2 = trim1?.replace(/\D.*/g, "");
  let last = trim2?.split("")?.pop();
  // let idLast = id?.toString()?.split("")?.pop();
  return colorJson[last] ?? "#778BEB";
};

export const alignmentType = {
  card: "card",
  list: "list",
};

export const genericStyles = {
  cardWrapperShadows: (theme) => ({
    cardWrapperShadows: {
      background: "white",
      borderRadius: 16,
      boxShadow: theme?.palette?.primary?.boxShadow,
    },
  }),
};

export const converToReactSelect = (data, valueKey, labelKey) => {
  if (!data || !data?.length) {
    return [];
  }

  return data.map((val) => {
    return {
      ...val,
      value: val?.[valueKey],
      label: val?.[labelKey],
    };
  });
};

export function getCountryCodeOptions() {
  const options = converToReactSelect(countryCode, "value", "label");
  return options;
}

export const alertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

export const videoFormats = [
  "m1v",
  "mpeg",
  "mov",
  "qt",
  "mpa",
  "mpg",
  "mpe",
  "avi",
  "movie",
  "mp4",
];
export const imageFormats = [
  "ras",
  "xwd",
  "bmp",
  "jpe",
  "jpg",
  "jpeg",
  "xpm",
  "ief",
  "pbm",
  "tif",
  "gif",
  "ppm",
  "xbm",
  "tiff",
  "rgb",
  "pgm",
  "png",
  "pnm",
  "ico",
];

export let maxMobileNumberLimit = 9;

export const isNumberKey = (e, isNumber) => {
  if (isNumber) {
    if (e.target.value.length <= maxMobileNumberLimit) {
      if (!(e.which >= 48 && e.which <= 57)) e.preventDefault();
    }
  }
};

export const isNumberKeyDown = (e, isNumber) => {
  if (isNumber) {
    if (e.target.value.length <= maxMobileNumberLimit) {
      if (e.which === 38 || e.which === 40) e.preventDefault();
    }
  }
};

export const addressComponentTypes = {
  addressLine1: "addressLine1",
  addressLine2: "addressLine2",
  city: "city",
  state: "state",
  country: "country",
  postalCode: "postalCode",
};

export const googleAddressComponentTypes = {
  street_number: "street_number",
  route: "route",
  postal_code: "postal_code",
  postal_code_suffix: "postal_code_suffix",
  locality: "locality",
  sublocality: "sublocality",
  sublocality_level_1: "sublocality_level_1",
  administrative_area_level_1: "administrative_area_level_1",
  country: "country",
};

// Custom price component
export const CustomPriceComponent = (
  price,
  currency,
  isDetailPage,
  isActualAmout
) => {
  if (price) {
    price = parseFloat(price)?.toFixed(2);
    let _price = price.split(".");
    let _checkDotIsExistsInThePrice = _price.length > 1;
    if (_checkDotIsExistsInThePrice) {
      return (
        <div
          style={{
            fontSize: isActualAmout ? "0.75rem" : "1.2rem",
            color: isActualAmout
              ? "#9297a5"
              : isDetailPage
              ? "#FFA811"
              : "black",
              textDecoration: isActualAmout ? 'line-through' : 'none',
              display: 'inline-block',
              marginLeft: isActualAmout ? 6 : 0
          }}
        >
          <span>
            {currency} {_price[0]}
          </span>
          .<span style={{ fontSize: isActualAmout ? "0.75rem" : "0.84rem" }}>{_price[1]}</span>
        </div>
      );
    } else {
      return (
        <div>
          <span
            style={{
              fontSize: isActualAmout ? "0.75rem" : "1.2rem",
              color: isActualAmout
                ? "#9297a5"
                : isDetailPage
                ? "#FFA811"
                : "black",
            }}
          >
            {currency} {_price[0]}
          </span>
          .<span style={{ fontSize: isActualAmout ? "0.75rem" : "0.84rem" }}>00</span>
        </div>
      );
    }
  } else {
    return (
      <div>
        <span
          style={{
            fontSize: isActualAmout ? "0.75rem" : "1.2rem",
            color: isActualAmout
              ? "#9297a5"
              : isDetailPage
              ? "#FFA811"
              : "black",
          }}
        >
          00
        </span>
        .<span style={{ fontSize: isActualAmout ? "0.75rem" : "0.84rem" }}>00</span>
      </div>
    );
  }
};

export const allPersonaIds = {
  service: "b6aea2a2-f752-4e82-9db4-bc6514f9f2ea",
  package: "3c968f29-e785-4910-b62a-b7a8a8960c23",
  product: "8641fabf-feb6-4b14-b060-5ffe75388d1c",
};

export const serviceTypeOptions = [
  {
    label: "Product",
    value: allPersonaIds.product,
  },
  {
    label: "Package",
    value: allPersonaIds.package,
  },
  {
    label: "Service",
    value: allPersonaIds.service,
  },
];

export const filterType = {
  CATEGORY: "CATEGORY",
  SERVICETYPE: "SERVICETYPE",
};

export const scrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

export const getSplicedData = (data) => {
  let x = [...data];
  x.splice(0, 1);

  return x;
};

export const encrypt_local = (data) => {
  var CryptoJS = require("crypto-js");

  return CryptoJS.AES.encrypt(
    data,
    CryptoJS.enc.Hex.parse("36ebe205bcdfc499a25e6923f4450fa8"),
    {
      iv: CryptoJS.enc.Hex.parse("be410fea41df7162a679875ec131cf2c"),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString();
};

// Function that takes a space seperated string and returns camelcase string with whitespace removed and append a ID at the end
export const createTestProps = (str = "default test id", id) => {
  let testId = str
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")

    if(!!id){
      testId = testId.concat(`${id}`)
    }

  console.log(`%c ${testId}`, "background: #4B3F72; color: #ffffff");

  return { id: testId, "aria-labelledby": testId };
};

