import React from "react";
import { CartContext } from "./contexts";

class AppCartContext extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      setProducts: this.setProducts,
    };
  }
  setProducts = (products) => {
    this.setState({ products });
  };
  componentDidMount() {}

  render() {
    // let { loading, error, data } = this.props?.loginState;

    return (
      // loading && error && !data ? <LinearProgress /> :
      <CartContext.Provider value={this.state}>
        {this.props.children}
      </CartContext.Provider>
    );
  }
}

export default AppCartContext;
