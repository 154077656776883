import React from "react";
import { alertProps, drawerProps, themes } from "../utils";

export const CartContext = React.createContext({
  products: [],
  setProducts: () => {},
});

export const ThemeContext = React.createContext({
  name: themes.default,
  setTheme: () => null,
});

export const AlertContext = React.createContext({
  open: false,
  severity: alertProps.severity.success,
  msg: "",
  vertical: alertProps.vertical.top,
  horizontal: alertProps.horizontal.center,
  onclose: () => null,
  setSnack: () => null,
});

export const DrawerContext = React.createContext({
  open: true,
  direction: drawerProps.direction.right,
  variant: drawerProps.variant.temporary,
  onClose: () => null,
  setDrawer: () => null,
});