import { store as ReduxStore } from "./redux/store";
import { Provider } from "react-redux";
import "./App.css";
import AppTheme from "./App.theme";
import RouterApp from "./router";
import { CssBaseline } from "@material-ui/core";
import AppCartContext from "./App.cart";
import AppErrorBoundary from "./App.error_boundry";
import AppAlert from "./App.alert";
import GlobalStyles from "./components/globalStyles";
import AppDrawer from "./App.drawer";

function App() {
  return (
    <div className="App">
      <Provider store={ReduxStore}>
        <AppTheme>
          <AppErrorBoundary>
            <CssBaseline />
            <GlobalStyles />
            <AppCartContext>
              <AppAlert>
              <AppDrawer>
                <RouterApp />
                </AppDrawer>
              </AppAlert>
            </AppCartContext>
          </AppErrorBoundary>
        </AppTheme>
      </Provider>
    </div>
  );
}

export default App;
