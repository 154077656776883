import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*::-webkit-scrollbar": {
        width: 8,
        backgroundColor: "#F5F5F5",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
        borderRadius: 10,
        backgroundColor: "#F5F5F5",
      },
      "*::-webkit-scrollbar-thumb": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
        borderRadius: 10,
        backgroundColor: "#A3A3A3",
      },
    },
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
