const routes = {
    Home:"/",
    ListingMain:"/listing/",
    Listing:"/listing/:id",
    DetailsMain:"/details",
    Details:"/details/:type/:id/:versionId",
    Cart:"/cart",
    Checkout: "/checkout",
}

export {routes}