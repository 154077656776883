import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { Cart, Checkout, Details, Home, Listing } from "../screens/enquiry";
import { routes } from "./routes";

const Cart = React.lazy(async() => await import("../screens/enquiry/cart"));
const Checkout = React.lazy(async () => await import("../screens/enquiry/checkout"));
const Details = React.lazy(async () => await import("../screens/enquiry/details"));
// const Home = React.lazy(async () => await import("../screens/enquiry/home"));
const Listing = React.lazy(async () => await import("../screens/enquiry/listing"));
const NotFound = React.lazy(async () => await import("../screens/notFound"));

const RouterApp = () => { 
  return (
    <Router>
      <React.Suspense fallback={<></>}>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path={routes.Home} render={() => <Listing />} />

          <Route path={routes.Listing}>
            <Listing />
          </Route>
          <Route path={routes.Details}>
            <Details />
          </Route>
          <Route path={routes.Cart}>
            <Cart />
          </Route>
          <Route path={routes.Checkout}>
            <Checkout />
          </Route>
          <Route path="*" component={NotFound} />
        </Switch>
      </React.Suspense>
    </Router>
  );
};

export default RouterApp;
